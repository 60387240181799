// TIER Framework Plugin
import events from './Events';

export default {
    install(Vue, options) {
        let router = options.router;

        let TIER = Vue.observable({
            cards: [],
            cardDefinitions: options.cardDefinitions || [],
            activeCard: 0,
            cardCount: 0,
            saveAlert: {
                active: false,
                message: 'Are you done? Unsaved changes will be lost.'
            }
        });

        console.log('TIER: Initialized');

        router.afterEach((to, from) => {
            if (to.params && to.params.cards) {
                // console.log('TIER: Route change', to, from);
                let data = to.params.cards.split(';');
                console.log('TIER: Parsing cards: ' + to.params.cards);

                TIER.cards = [];

                for (let i = 0; i < data.length; i++) {
                    let parsed = data[i].split(',');

                    let def = lookupDefById(parsed[0]);
                    let newCard = {
                        props: {},
                        propsPath: parsed.slice(1).join(','),
                        definition: def,
                        child: null,
                        title: null
                    };

                    for (let j = 1; j < parsed.length; j++) {
                        newCard.props[def.props[j - 1]] = parsed[j];
                    }

                    if (TIER.cards.length) TIER.cards[TIER.cards.length - 1].child = newCard;
                    TIER.cards.push(newCard);
                }
            }
        });

        Vue.prototype.$openCard = function (card, props, from) {

            let def = lookupDefByComponent(card);

            if (def) {
                let path = getRootPath(router.currentRoute.path);

                // Start from an existing card, dropping the rest of the children
                if (from) {
                    let pathData = router.currentRoute.path.split('/').slice(-1)[0].split(';');
                    path += pathData.slice(0, TIER.cards.indexOf(from) + 1).join(';') + ';';
                }

                path += def.pathId;

                // Put prop data in path
                if (def.props) {
                    for (let i = 0; i < def.props.length; i++) {
                        if (props[def.props[i]]) path += ',' + props[def.props[i]];
                    }
                }

                // TODO, should this somehow refresh the data if same path requested again
                // console.log(router.currentRoute.path);
                // console.log(path);

                if (router.currentRoute.path !== path) {
                    if (TIER.saveAlert.active && !window.confirm(TIER.saveAlert.message)) {
                        console.log('TIER: Cancelled open card');
                        return;
                    }
                } else {
                    return;
                }

                console.log('TIER: Open card: ' + card);
                TIER.saveAlert.active = false;
                router.push(path);
            } else {
                console.error('TIER: Invalid card: ' + card);
            }
        };

        Vue.prototype.$reloadCard = function (card, props) {
            // console.log('TIER: Reload Card', {card, props});
            events.$emit('TIER_reloadCard', {card, props});
        };

        Vue.prototype.$closeCard = (card) => {
            if (TIER.saveAlert.active && !window.confirm(TIER.saveAlert.message)) {
                console.log('TIER: Cancelled close card');
                return;
            }

            console.log('TIER: Close card');

            let cards = TIER.cards
                .slice(0, TIER.cards.indexOf(card))
                .map(val => val.definition.pathId + ((val.propsPath) ? ',' + val.propsPath : ''))
                .join(';');

            if (!cards) TIER.cards = [];

            TIER.saveAlert.active = false;
            router.push(getRootPath(router.currentRoute.path) + cards);
        };

        Vue.prototype.$setActiveCard = (index) => {
            TIER.activeCard = index;
        };

        Vue.prototype.$resetCards = () => {
            if (TIER.saveAlert.active && !window.confirm(TIER.saveAlert.message)) {
                console.log('TIER: Cancelled reset');
                return;
            }

            console.log('TIER: Reset cards');
            TIER.cards = [];
            TIER.saveAlert.active = false;
            if (router.currentRoute.path !== '/') router.push(getRootPath(router.currentRoute.path));
        };

        Vue.prototype.$saveAlert = (value, message) => {
            TIER.saveAlert.active = value;
            TIER.saveAlert.message = message;
        };

        Vue.prototype.$TIER = TIER;

        function lookupDefByComponent(card) {
            for (let i = 0; i < TIER.cardDefinitions.length; i++) {
                const def = TIER.cardDefinitions[i];
                if (def.component && def.component === card) return def;
            }
            console.log('TIER: Invalid card by component: ' + card);
        }

        function lookupDefById(card) {
            for (let i = 0; i < TIER.cardDefinitions.length; i++) {
                const def = TIER.cardDefinitions[i];
                if (def.pathId && def.pathId === card) return def;
            }
            console.log('TIER: Invalid card by pathId: ' + card);
        }

        function getRootPath(path) {
            let parsed = path.split('/');
            return (parsed.length > 2) ? parsed.slice(0, -1).join('/') + '/' : '/' + parsed[1] + '/';
        }
    }
};
