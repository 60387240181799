<template>
    <div class="t-stack">
        <!-- TODO - Dynamic animations? https://stackoverflow.com/questions/42289549/dynamic-transition-component-vuejs-2 -->
        <transition-group>
            <card v-for="(card, index) in $TIER.cards"
                  v-show="index >= $TIER.activeCard && index < $TIER.activeCard + $TIER.cardCount"
                  :key="index"
                  :card="card"
                  :index="index"
            />
        </transition-group>
    </div>
</template>

<script>
    import Card from './Card';

    export default {
        name: 'Stack',
        components: {Card},
        data: function () {
            return {
                windowWidth: 0
            };
        },
        methods: {
            calcCardCount: function() {
                let c = 0, w = 0;
                for (let i = this.$TIER.activeCard; i < this.$TIER.cards.length; i++) {
                    w += this.getCardSize(this.$TIER.cards[i].definition.size);
                    if (w < this.windowWidth) {
                        c++;
                    } else {
                        break;
                    }
                }
                this.$TIER.cardCount = Math.max(1, c);
            },
            // TODO refactor
            getCardSize: function (size) {
                let padding = 64;
                if (size === 'sm') return 400 + padding;
                if (size === 'md') return 600 + padding;
                if (size === 'lg') return 800 + padding;
                if (size === 'xl') return 1000 + padding;
                if (size === 'xll') return 1200 + padding;
            },
            setActiveCard: function() {
                let c = 0, w = 0;
                for (let i = this.$TIER.cards.length - 1; i >= 0; i--) {
                    w += this.getCardSize(this.$TIER.cards[i].definition.size);
                    if (w < this.windowWidth) {
                        c++;
                    } else {
                        break;
                    }
                }
                this.$TIER.activeCard = Math.max(this.$TIER.cards.length - Math.max(1, c), 0);
                this.calcCardCount();
            }
        },
        watch: {
            '$TIER.cards': function (newVal) {
                this.setActiveCard();
            }
        },
        mounted() {
            this.windowWidth = window.innerWidth;
            let timer;
            window.addEventListener('resize', () => {
                if (timer) clearTimeout(timer);
                timer = setTimeout(() => {
                    this.windowWidth = window.innerWidth;
                    this.calcCardCount();
                }, 500);
            });
            this.calcCardCount();
            this.setActiveCard();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    };
</script>

<style lang="scss" scoped>
    .t-stack {
        /* span gets rendered for vue transition-group component */
        > span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            /*transition: all 1s;*/
        }
    }
</style>
