<template>
    <div id="login-frame">
        <div class="auth-card container is-max-desktop">
            <div class="columns">
                <div class="column is-5 left-side has-text-centered">
                    <img src="@/assets/roger.svg" alt="Tennis Ventures">
                    <div class="has-text-grey" style="font-size: 0.8rem">"Roger" v{{version}}</div>
                </div>

                <div class="column right-side">
                    <h6 class="mb-5">
                        <b-icon icon="lock" class="fa-sm mr-2 has-text-grey"></b-icon>
                        Login
                    </h6>

                    <b-field>
                        <b-input placeholder="Email Address" type="email" icon="envelope" v-model="email" v-on:keyup.native.enter="formSubmit"></b-input>
                    </b-field>

                    <b-field>
                        <b-input placeholder="Password" type="password" icon="key" v-model="password" v-on:keyup.native.enter="formSubmit"></b-input>
                    </b-field>

                    <b-button type="is-primary" icon-right="sign-in-alt" class="mt-4 pr-5" :disabled="processing || !email || !password" @click="formSubmit">Login</b-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import pkg from '../../package.json';

    export default {
        name: 'Login',
        data: function () {
            return {
                email: '',
                password: '',
                processing: false,
                version: pkg.version
            };
        },
        methods: {
            ...mapActions(['login']),
            formSubmit() {
                if (this.email && this.password) {
                    this.login({email: this.email, password: this.password}).then(response => {
                        console.log('login success');
                        this.$router.push('/secure');
                    }).catch(error => {
                        console.log('login error');
                        console.error(error);
                    });
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    #login-frame {
        display: flex;
        min-height: 100vh;
        align-items: center;
        justify-content: center;

        background: #252722 radial-gradient(#32322e, #252722);

        .auth-card {
            margin: 2rem;
            border-radius: 5px;
            box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.3);

            color: #242624;

            .left-side {
                image-rendering: -webkit-optimize-contrast;

                background-color: #ecece9;
                padding: 3rem 2rem 3rem 2.5rem;
                border-radius: 5px 0 0 5px;

                color: #d9e3ea;

                img {
                    width: 100%;
                    max-width: 230px;
                }
            }

            .right-side {
                background-color: #FFFFFF;
                padding: 3.5rem 3rem 4.5rem 3rem;
                border-radius: 0 5px 5px 0;

                font-size: 0.85rem;

                h6 {
                    font-size: 1.3rem;
                }
            }
        }
    }
</style>
