import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import {axiosInstance, client as http} from "@/http_client";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {user},
    state: {
        tasks: {
            totals: {},
            assigned: {}
        },
        assignees: []
    },
    mutations: {
        setTasks(state, payload) {
            state.tasks = payload;
        },
        setAssignees(state, assignees) {
            state.assignees = assignees;
        }
    },
    getters: {
        tasks: state => state.tasks,
        assignees: state => state.assignees
    },
    actions: {
        updateTasks({commit}) {
            return new Promise((resolve, reject) => {
                http.get('/api/tasks/', {force: true}).then(response => {
                    commit('setTasks', response.data);
                    resolve(response.data);
                }, err => {
                    reject(err.response.data);
                });
            });
        }
    }
});
