<template>
    <div class="t-card" :class="card.definition.size">
        <div class="t-card-controls">
            <!--            <i class="fas fa-fw fa-thumbtack"></i>-->
            <!--            <i class="fas fa-fw fa-bars"></i>-->
            <i class="fas fa-fw fa-sync" v-if="card.definition.reload" @click="reload"></i>
            <i class="fas fa-fw fa-times" @click="closeCard()"></i>
        </div>

        <div class="loading" v-show="loading">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>

        <div
             ref="card"
             class="t-card-content"

             :is="card.definition.component"
             :card="card"
             :props="card.props"
             :child="card.child"

             @title="setTitle"
             @loading="setLoading"
        ></div>
    </div>
</template>

<script>
    import events from '../Events';

    export default {
        name: 'Card',
        props: ['card', 'index'],
        data: function () {
            return {
                loading: true
            };
        },
        methods: {
            setTitle: function (title) {
                this.card.title = title;
            },
            setLoading: function (val) {
                this.loading = val;
            },
            closeCard: function () {
                // Does card have a custom close handler?
                if (this.$refs && this.$refs.card && this.$refs.card.close) {
                    this.$refs.card.close();
                } else {
                    this.$closeCard(this.card);
                }
            },
            reload: function () {
                console.log('TIER: Card Reload Requested: ' + this.card.definition.component, this.card);
                if (this.$refs && this.$refs.card && this.$refs.card.reload) {
                    this.$refs.card.reload();
                } else {
                    console.warn('TIER: No reload handler available');
                }
            }
        },
        mounted: function () {
            this.loading = !!this.card.definition.loading;

            events.$on('TIER_reloadCard', data => {
                if (this.card.definition.component === data.card) {
                    if (this.card.props && data.props) {
                        let valid = true;
                        for (const [key, value] of Object.entries(data.props)) {
                            if (value != this.card.props[key]) valid = false;
                        }
                        if (valid) this.reload();
                    } else {
                        this.reload();
                    }
                }
            });
        }
    };
</script>

<style lang="scss" scoped>
    .t-card {
        height: 99%;

        &.sm {
            max-width: 400px;
            width: 100%;
        }

        &.md {
            max-width: 600px;
            width: 100%;
        }

        &.lg {
            max-width: 800px;
            width: 100%;
        }

        &.xl {
            max-width: 1000px;
            width: 100%;
        }

        &.xxl {
            max-width: 1200px;
            width: 100%;
        }

        margin: 0 1.5rem;
        position: relative;

        background: white;
        border-radius: 5px;

        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.4);
        overflow: hidden;

        /*transition: all 1s;*/

        .t-card-controls {
            position: absolute;
            right: 0;
            top: 0;
            background-color: desaturate(lighten($t-primary, 30), 70);
            color: white;
            padding: 2px 4px;
            z-index: 1000;

            border-bottom-left-radius: 5px;

            i {
                font-size: 0.7rem;
                width: 1.5rem;
                height: 1.5rem;
                line-height: 26px;
                cursor: pointer;

                &.fa-times {
                    font-size: 0.8rem;
                }

                &.fa-thumbtack {
                    font-size: 0.65rem;
                }

                &.fa-bars {
                    font-size: 0.75rem;
                }

                &:hover {
                    color: $t-primary;
                }
            }
        }

        .t-card-content {
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
        }

        /*&:only-child, &:last-child {*/
        /*    margin-right: 0;*/
        /*}*/

        // Loading

        .loading {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 100;

            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);

            padding-top: 8rem;
            text-align: center;

            .spinner {
                margin: 1rem auto;
                width: 200px;
                height: 40px;
                text-align: center;
                font-size: 10px;

                > div {
                    background-color: lighten($t-primary, 10);
                    height: 100%;
                    width: 6px;
                    display: inline-block;
                    margin: 0 2px;

                    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
                    animation: sk-stretchdelay 1.2s infinite ease-in-out;
                }

                .rect2 {
                    -webkit-animation-delay: -1.1s;
                    animation-delay: -1.1s;
                }

                .rect3 {
                    -webkit-animation-delay: -1.0s;
                    animation-delay: -1.0s;
                }

                .rect4 {
                    -webkit-animation-delay: -0.9s;
                    animation-delay: -0.9s;
                }

                .rect5 {
                    -webkit-animation-delay: -0.8s;
                    animation-delay: -0.8s;
                }
            }

            @-webkit-keyframes sk-stretchdelay {
                0%, 40%, 100% {
                    -webkit-transform: scaleY(0.4)
                }
                20% {
                    -webkit-transform: scaleY(1.0)
                }
            }

            @keyframes sk-stretchdelay {
                0%, 40%, 100% {
                    transform: scaleY(0.4);
                    -webkit-transform: scaleY(0.4);
                }
                20% {
                    transform: scaleY(1.0);
                    -webkit-transform: scaleY(1.0);
                }
            }
        }

        @media (max-width: 600px) {
            margin: 10px !important;
        }
    }
</style>
