import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import Buefy from 'buefy';

// All options available at: https://buefy.org/documentation/constructor-options
Vue.use(Buefy, {
    defaultIconPack: 'fas'
});

import VueMoment from "vue-moment";
import moment from 'moment-timezone';

Vue.use(VueMoment, {
    moment,
});

import VueQuill from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuill);

import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, {});

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter,
    {
        symbol: '$',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: false
    });

import TIER from "./TIER/TIER";
import cardDefinitions from './cards';

Vue.use(TIER, {
    router: router,
    cardDefinitions: cardDefinitions
});

import {axiosInstance} from "./http_client";

const token = localStorage.getItem('token');
if (token) {
    console.log('Setting token.');
    axiosInstance.defaults.headers.common['Authorization'] = token;
}

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (err) {
    if (err.response.status === 401) {
        console.log('Received 401, signing out.');
        store.dispatch('signOut');
        if (router.currentRoute.path !== '/') router.push('/');
    }
    return Promise.reject(err);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

Vue.filter('tel', function (phone) {
    if (phone) {
        return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
        return 'N/A';
    }
});

Vue.filter('capitalize', function (value) {
    if (value) {
        return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    } else {
        return '';
    }
});

Vue.filter('formatSize', function (num) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
    }

    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
});
