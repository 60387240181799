import Vue from 'vue';

// Definitions
export default [
    // Tasks
    {
        pathId: 'a',
        component: 'tasks',
        title: 'Tasks',
        icon: 'fa-tasks',
        size: 'sm', //sm, md, lg, xl
        reload: true,
        loading: true
    },
    {
        pathId: 'ad',
        component: 'task-details',
        title: 'Task Details',
        icon: 'fa-tasks',
        size: 'sm', //sm, md, lg, xl
        props: ['type'],
        reload: true,
        loading: true
    },
    // Trips
    {
        pathId: 'ts',
        component: 'trips',
        title: 'Trips',
        icon: 'fa-plane',
        size: 'md', //sm, md, lg, xl
        loading: true
    },
    {
        pathId: 'tvs',
        component: 'archived-trips',
        title: 'Archived Trips',
        icon: 'fa-plane-arrival',
        size: 'md', //sm, md, lg, xl
        loading: true
    },
    {
        pathId: 't',
        component: 'trip',
        title: 'Trip',
        icon: 'fa-tennis-ball',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tn',
        component: 'new-trip',
        title: 'New Trip',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
    },
    {
        pathId: 'te',
        component: 'edit-trip',
        title: 'Edit Trip',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tdup',
        component: 'duplicate-trip',
        title: 'Duplicate Trip',
        icon: 'fa-copy',
        size: 'md', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tdupds',
        component: 'duplicate-trip-days',
        title: 'Duplicate Itinerary',
        icon: 'fa-copy',
        size: 'md', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tr',
        component: 'trip-reports',
        title: 'Trip Reports',
        icon: 'fa-clipboard-list-check',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tds',
        component: 'days',
        title: 'Trip Itinerary',
        icon: 'fa-calendar-alt',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'td',
        component: 'day',
        title: 'Day',
        icon: 'fa-calendar-day',
        size: 'sm', //sm, md, lg, xl
        props: ['dayId'],
        loading: true
    },
    {
        pathId: 'tdn',
        component: 'new-day',
        title: 'New Day',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['tripId']
    },
    {
        pathId: 'tde',
        component: 'edit-day',
        title: 'Edit Day',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'dayId']
    },
    {
        pathId: 'tden',
        component: 'new-event',
        title: 'New Event',
        icon: 'fa-plus',
        size: 'lg', //sm, md, lg, xl
        props: ['tripId', 'dayId'],
        loading: true
    },
    {
        pathId: 'tdee',
        component: 'edit-event',
        title: 'Edit Event',
        icon: 'fa-pencil',
        size: 'lg', //sm, md, lg, xl
        props: ['tripId', 'dayId', 'eventId'],
        loading: true
    },
    {
        pathId: 'tdpn',
        component: 'new-day-photo',
        title: 'New Day Photo',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'dayId']
    },
    {
        pathId: 'tdpe',
        component: 'edit-day-photo',
        title: 'Edit Day Photo',
        icon: 'fa-pencil',
        size: 'sm', //sm, md, lg, xl
        props: ['photoId', 'dayId'],
        loading: true
    },
    {
        pathId: 'tas',
        component: 'trip-addons',
        title: 'Trip Add-Ons',
        icon: 'fa-layer-plus',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tasl',
        component: 'addons-list',
        title: 'Trip Add-Ons',
        icon: 'fa-layer-plus',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId', 'type'],
        loading: true
    },
    {
        pathId: 'ta',
        component: 'trip-addon',
        title: 'Trip Add-On',
        icon: 'fa-layer-plus',
        size: 'sm', //sm, md, lg, xl
        props: ['addonId'],
        loading: true
    },
    {
        pathId: 'taps',
        component: 'addon-participants',
        title: 'Add-On Participants',
        icon: 'fa-street-view',
        size: 'sm', //sm, md, lg, xl
        props: ['addonId'],
        loading: true
    },
    {
        pathId: 'tan',
        component: 'new-trip-addon',
        title: 'New Trip Add-On',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl,
        props: ['tripId']
    },
    {
        pathId: 'tae',
        component: 'edit-trip-addon',
        title: 'Edit Trip Add-On',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['addonId'],
        loading: true
    },
    {
        pathId: 'ths',
        component: 'trip-hotels',
        title: 'Trip Hotels',
        icon: 'fa-hotel',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'thn',
        component: 'new-trip-hotel',
        title: 'New Trip Hotel',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['tripId']
    },
    {
        pathId: 'th',
        component: 'trip-hotel',
        title: 'Trip Hotel',
        icon: 'fa-hotel',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId', 'hotelId'],
        loading: true
    },
    {
        pathId: 'the',
        component: 'edit-trip-hotel',
        title: 'Edit Trip Hotel',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'hotelId'],
        loading: true
    },
    {
        pathId: 'tqs',
        component: 'questions',
        title: 'Registration Questions',
        icon: 'fa-clipboard-list-check',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'thrn',
        component: 'new-room-type',
        title: 'New Room Type',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'hotelId']
    },
    {
        pathId: 'thre',
        component: 'edit-room-type',
        title: 'Edit Room Type',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'hotelId', 'roomId'],
        loading: true
    },
    {
        pathId: 'tq',
        component: 'question',
        title: 'Question',
        icon: 'fa-clipboard-list-check',
        size: 'sm', //sm, md, lg, xl
        props: ['questionId'],
        loading: true
    },
    {
        pathId: 'tqn',
        component: 'new-question',
        title: 'New Question',
        icon: 'fa-plus',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
    },
    {
        pathId: 'tqe',
        component: 'edit-question',
        title: 'Edit Question',
        icon: 'fa-pencil',
        size: 'sm', //sm, md, lg, xl
        props: ['questionId'],
        loading: true
    },
    {
        pathId: 'tqan',
        component: 'new-answer',
        title: 'New Answer',
        icon: 'fa-plus',
        size: 'sm', //sm, md, lg, xl
        props: ['questionId'],
    },
    {
        pathId: 'tqae',
        component: 'edit-answer',
        title: 'Edit Answer',
        icon: 'fa-pencil',
        size: 'sm', //sm, md, lg, xl
        props: ['questionId', 'answerId'],
        loading: true
    },
    {
        pathId: 'tqrs',
        component: 'question-responses',
        title: 'Question Responses',
        icon: 'fa-poll-people',
        size: 'sm', //sm, md, lg, xl
        props: ['questionId'],
        loading: true
    },
    {
        pathId: 'tps',
        component: 'trip-photos',
        title: 'Trip Photos',
        icon: 'fa-images',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tpe',
        component: 'edit-trip-photo',
        title: 'Edit Trip Photo',
        icon: 'fa-pencil',
        size: 'sm', //sm, md, lg, xl
        props: ['photoId'],
        loading: true
    },
    {
        pathId: 'tpn',
        component: 'new-trip-photo',
        title: 'New Trip Photo',
        icon: 'fa-plus',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId']
    },
    {
        pathId: 'to',
        component: 'reg-overview',
        title: 'Registration Overview',
        icon: 'fa-users',
        size: 'lg', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tfs',
        component: 'trip-flights',
        title: 'Participant Flights',
        icon: 'fa-plane',
        size: 'md', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tns',
        component: 'trip-notes',
        title: 'Trip Notes',
        icon: 'fa-sticky-note',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId'],
        loading: true
    },
    {
        pathId: 'tnn',
        component: 'new-trip-note',
        title: 'New Trip Note',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['tripId']
    },
    {
        pathId: 'tne',
        component: 'edit-trip-note',
        title: 'Edit Trip Note',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'noteId'],
        loading: true
    },
    {
        pathId: 'tao',
        component: 'trip-app',
        title: 'Navigator Options',
        icon: 'fa-compass',
        size: 'sm', //sm, md, lg, xl
        props: ['tripId']
    },
    {
        pathId: 'taoe',
        component: 'trip-app-editor',
        title: 'Navigator Options',
        icon: 'fa-pencil',
        size: 'lg', //sm, md, lg, xl
        props: ['tripId', 'type']
    },
    // Registrations
    {
        pathId: 'rl',
        component: 'registrations',
        title: 'Registrations',
        icon: 'fa-suitcase-rolling',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        reload: true,
        props: ['tripId']
    },
    {
        pathId: 'r',
        component: 'registration',
        title: 'Registration',
        icon: 'fa-suitcase',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        props: ['registrationId']
    },
    {
        pathId: 'rn',
        component: 'new-registration',
        title: 'New Registration',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
    },
    {
        pathId: 'r-e',
        component: 'edit-registration',
        title: 'Edit Registration',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['registrationId']
    },
    {
        pathId: 'ri',
        component: 'itinerary',
        title: 'Registration Itinerary',
        icon: 'fa-calendar-alt',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        props: ['tripId', 'registrationId']
    },
    {
        pathId: 'ritm',
        component: 'itemized',
        title: 'Itemized Registration',
        icon: 'fa-file-invoice',
        size: 'xl', //sm, md, lg, xl
        loading: true,
        props: ['regId']
    },
    {
        pathId: 'rrd',
        component: 'reg-days',
        title: 'Registration Days',
        icon: 'fa-calendar-check',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        props: ['tripId', 'registrationId']
    },
    {
        pathId: 're',
        component: 'events',
        title: 'Events',
        icon: 'fa-calendar-alt',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        props: ['tripId', 'registrationId', 'dayId']
    },
    {
        pathId: 'ren',
        component: 'new-custom-event',
        title: 'New Custom Event',
        icon: 'fa-plus',
        size: 'lg', //sm, md, lg, xl
        props: ['registrationId', 'dayId'],
        loading: true
    },
    {
        pathId: 'ree',
        component: 'edit-custom-event',
        title: 'Edit Custom Event',
        icon: 'fa-pencil',
        size: 'lg', //sm, md, lg, xl
        props: ['registrationId', 'dayId', 'eventId'],
        loading: true
    },
    {
        pathId: 'rfs',
        component: 'flights',
        title: 'Flights',
        icon: 'fa-plane',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId'],
        loading: true
    },
    {
        pathId: 'rfn',
        component: 'new-flight',
        title: 'New Flight',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId']
    },
    {
        pathId: 'rhs',
        component: 'reg-hotels',
        title: 'Hotels',
        icon: 'fa-hotel',
        size: 'sm', //sm, md, lg, xl
        props: ['registrationId'],
        loading: true
    },
    {
        pathId: 'rhn',
        component: 'new-reg-hotel',
        title: 'New Hotel',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId'],
        loading: true
    },
    {
        pathId: 'rhe',
        component: 'edit-reg-hotel',
        title: 'Edit Hotel',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId', 'hotelId'],
        loading: true
    },
    {
        pathId: 'rfe',
        component: 'edit-flight',
        title: 'Edit Flight',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId', 'flightId'],
        loading: true
    },
    {
        pathId: 'roen',
        component: 'new-override-event',
        title: 'Customize Event',
        icon: 'fa-cog',
        size: 'md', //sm, md, lg, xl
        props: ['tripId', 'registrationId', 'dayId', 'eventId']
    },
    {
        pathId: 'roee',
        component: 'edit-override-event',
        title: 'Customize Event',
        icon: 'fa-cog',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId', 'dayId', 'eventId'],
        loading: true
    },
    {
        pathId: 'ras',
        component: 'addons',
        title: 'Add-Ons',
        icon: 'fa-layer-plus',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        props: ['registrationId']
    },
    {
        pathId: 'ran',
        component: 'new-addon',
        title: 'New Add-on',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['registrationId', 'tripId']
    },
    {
        pathId: 'rae',
        component: 'edit-addon',
        title: 'Edit Add-on',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['registrationId', 'addonId']
    },
    {
        pathId: 'rqs',
        component: 'responses',
        title: 'Question Responses',
        icon: 'fa-clipboard-list-check',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['registrationId']
    }, {
        pathId: 'rqn',
        component: 'new-response',
        title: 'New Response',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['registrationId', 'tripId']
    }, {
        pathId: 'rqe',
        component: 'edit-response',
        title: 'Edit Response',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['registrationId', 'responseId']
    },
    {
        pathId: 'rns',
        component: 'reg-notes',
        title: 'Registration Notes',
        icon: 'fa-sticky-note',
        size: 'sm', //sm, md, lg, xl
        props: ['registrationId'],
        loading: true
    },
    {
        pathId: 'rnn',
        component: 'new-reg-note',
        title: 'New Registration Note',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId']
    },
    {
        pathId: 'rne',
        component: 'edit-reg-note',
        title: 'Edit Registration Note',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['registrationId', 'noteId'],
        loading: true
    },
    // Customers
    {
        pathId: 'cs',
        component: 'customers',
        title: 'Customers',
        icon: 'fa-users',
        size: 'sm', //sm, md, lg, xl
        reload: true
    },
    {
        pathId: 'csq',
        component: 'search-customers',
        title: 'Search Customers',
        icon: 'fa-users',
        size: 'sm', //sm, md, lg, xl
        props: ['query']
    },
    {
        pathId: 'c',
        component: 'customer',
        title: 'Customer',
        icon: 'fa-user',
        size: 'sm', //sm, md, lg, xl
        loading: true,
        props: ['userId']
    },
    {
        pathId: 'cn',
        component: 'new-customer',
        title: 'New Customer',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
    },
    {
        pathId: 'ce',
        component: 'edit-customer',
        title: 'Edit Customer',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        loading: true,
        props: ['userId']
    },
    {
        pathId: 'cns',
        component: 'customer-notes',
        title: 'Customer Notes',
        icon: 'fa-sticky-note',
        size: 'sm', //sm, md, lg, xl
        props: ['userId'],
        loading: true
    },
    {
        pathId: 'cnn',
        component: 'new-customer-note',
        title: 'New Customer Note',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['userId']
    },
    {
        pathId: 'cne',
        component: 'edit-customer-note',
        title: 'Edit Customer Note',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['userId', 'noteId'],
        loading: true
    },
    {
        pathId: 'cds',
        component: 'customer-documents',
        title: 'Documents',
        icon: 'fa-id-card',
        size: 'sm', //sm, md, lg, xl
        props: ['userId'],
        loading: true
    },
    {
        pathId: 'cdn',
        component: 'new-document',
        title: 'New Document',
        icon: 'fa-plus',
        size: 'md', //sm, md, lg, xl
        props: ['userId']
    },
    {
        pathId: 'cde',
        component: 'edit-document',
        title: 'Edit Document',
        icon: 'fa-pencil',
        size: 'md', //sm, md, lg, xl
        props: ['userId', 'docId'],
        loading: true
    },
    {
        pathId: 'crs',
        component: 'customer-registrations',
        title: 'Customer Registrations',
        icon: 'fa-suitcase',
        size: 'sm', //sm, md, lg, xl
        props: ['userId'],
        loading: true
    },
    {
        pathId: 'csh',
        component: 'customer-share',
        title: 'Share Links',
        icon: 'fa-share-square',
        size: 'sm', //sm, md, lg, xl
        props: ['userId'],
        loading: true
    },
    // Payment Plans
    {
        pathId: 'pp',
        component: 'payment-plan',
        title: 'Payment Plan',
        icon: 'fa-credit-card-front',
        size: 'sm',
        loading: true,
        props: ['planId', 'regId']

    },
    {
        pathId: 'ppe',
        component: 'edit-payment-plan',
        title: 'Edit Payment Plan',
        icon: 'fa-pencil',
        size: 'md',
        loading: true,
        props: ['planId', 'regId']
    },
    {
        pathId: 'ppmp',
        component: 'make-payment',
        title: 'Make Payment',
        icon: 'fa-credit-card',
        size: 'md',
        loading: true,
        props: ['planId', 'regId']
    },
    {
        pathId: 'pppin',
        component: 'new-plan-item',
        title: 'New Payment Plan Item',
        icon: 'fa-plus',
        size: 'md',
        props: ['planId', 'regId']
    },
    {
        pathId: 'pppie',
        component: 'edit-plan-item',
        title: 'Edit Payment Plan Item',
        icon: 'fa-pencil',
        size: 'md',
        props: ['planId', 'itemId']
    },
    {
        pathId: 'pppmn',
        component: 'new-payment-method',
        title: 'New Payment Method',
        icon: 'fa-plus',
        size: 'md',
        props: ['planId', 'regId'],
        loading: true
    },
    {
        pathId: 'pppme',
        component: 'edit-payment-method',
        title: 'Edit Payment Method',
        icon: 'fa-pencil',
        size: 'md',
        props: ['planId', 'methodId']

    },
    {
        pathId: 'pptn',
        component: 'new-transaction',
        title: 'New Transaction',
        icon: 'fa-plus',
        size: 'md',
        props: ['regId', 'planId']
    },
    {
        pathId: 'ppte',
        component: 'edit-transaction',
        title: 'Edit Transaction',
        icon: 'fa-pencil',
        size: 'md',
        props: ['regId', 'planId', 'transactionId']
    },
    // Communication
    {
        pathId: 'cm',
        component: 'communication',
        title: 'Communication',
        icon: 'fa-comments-alt',
        size: 'sm', //sm, md, lg, xl
    },
    {
        pathId: 'mk',
        component: 'marketing',
        title: 'Marketing',
        icon: 'fa-search-dollar',
        size: 'sm', //sm, md, lg, xl
    },
    {
        pathId: 'rp',
        component: 'reports',
        title: 'Reports',
        icon: 'fa-clipboard-list-check',
        size: 'sm', //sm, md, lg, xl
    },
    {
        pathId: 's',
        component: 'settings',
        title: 'Settings',
        icon: 'fa-cogs',
        size: 'sm', //sm, md, lg, xl
    },
    {
        pathId: 'us',
        component: 'user-settings',
        title: 'User Settings',
        icon: 'fa-user-cog',
        size: 'sm', //sm, md, lg, xl
    },
    // Billing
    {
        pathId: 'b',
        component: 'billing',
        title: 'Billing',
        icon: 'fa-credit-card',
        size: 'sm'
    },
    {
        pathId: 'bd',
        component: 'billing-due',
        title: 'Plan Items Past Due',
        icon: 'fa-history',
        size: 'sm',
        loading: true,
        reload: true
    },
];

// Components

Vue.component('tasks', () => import('@/cards/tasks/Tasks'));
Vue.component('task-details', () => import('@/cards/tasks/Details'));

Vue.component('trips', () => import('@/cards/trips/Trips'));
Vue.component('archived-trips', () => import('@/cards/trips/ArchivedTrips'));
Vue.component('trip', () => import('@/cards/trips/Trip'));
Vue.component('new-trip', () => import('@/cards/trips/NewTrip'));
Vue.component('edit-trip', () => import('@/cards/trips/EditTrip'));
Vue.component('duplicate-trip', () => import('@/cards/trips/DuplicateTrip'));
Vue.component('duplicate-trip-days', () => import('@/cards/trips/DuplicateDays'));
Vue.component('trip-reports', () => import('@/cards/trips/reports/Reports'));
Vue.component('trip-addons', () => import('@/cards/trips/addons/TripAddOns'));
Vue.component('addons-list', () => import('@/cards/trips/addons/AddOnsList'));
Vue.component('trip-addon', () => import('@/cards/trips/addons/Addon'));
Vue.component('addon-participants', () => import('@/cards/trips/addons/AddonParticipants'));
Vue.component('new-trip-addon', () => import('@/cards/trips/addons/NewAddon'));
Vue.component('edit-trip-addon', () => import('@/cards/trips/addons/EditAddon'));
Vue.component('day', () => import('@/cards/trips/Day'));
Vue.component('new-day', () => import('@/cards/trips/NewDay'));
Vue.component('edit-day', () => import('@/cards/trips/EditDay'));
Vue.component('new-event', () => import('@/cards/trips/NewEvent'));
Vue.component('edit-event', () => import('@/cards/trips/EditEvent'));
Vue.component('new-day-photo', () => import('@/cards/trips/NewDayPhoto'));
Vue.component('edit-day-photo', () => import('@/cards/trips/EditDayPhoto'));
Vue.component('days', () => import('@/cards/trips/Days'));
Vue.component('trip-hotels', () => import('@/cards/trips/hotels/Hotels'));
Vue.component('new-trip-hotel', () => import('@/cards/trips/hotels/NewHotel'));
Vue.component('trip-hotel', () => import('@/cards/trips/hotels/Hotel'));
Vue.component('edit-trip-hotel', () => import('@/cards/trips/hotels/EditHotel'));
Vue.component('new-room-type', () => import('@/cards/trips/NewRoomType'));
Vue.component('edit-room-type', () => import('@/cards/trips/EditRoomType'));
Vue.component('questions', () => import('@/cards/trips/Questions'));
Vue.component('question', () => import('@/cards/trips/Question'));
Vue.component('new-question', () => import('@/cards/trips/NewQuestion'));
Vue.component('edit-question', () => import('@/cards/trips/EditQuestion'));
Vue.component('new-answer', () => import('@/cards/trips/NewAnswer'));
Vue.component('edit-answer', () => import('@/cards/trips/EditAnswer'));
Vue.component('question-responses', () => import('@/cards/trips/Responses'));
Vue.component('trip-photos', () => import('@/cards/trips/Photos'));
Vue.component('edit-trip-photo', () => import('@/cards/trips/EditTripPhoto'));
Vue.component('new-trip-photo', () => import('@/cards/trips/NewTripPhoto'));
Vue.component('reg-overview', () => import('@/cards/trips/RegistrationOverview'));
Vue.component('trip-flights', () => import('@/cards/trips/TripFlights'));
Vue.component('trip-notes', () => import('@/cards/trips/Notes'));
Vue.component('new-trip-note', () => import('@/cards/trips/NewNote'));
Vue.component('edit-trip-note', () => import('@/cards/trips/EditNote'));
Vue.component('trip-app', () => import('@/cards/trips/app/TripApp'));
Vue.component('trip-app-editor', () => import('@/cards/trips/app/AppEditor'));

Vue.component('registrations', () => import('@/cards/registrations/Registrations'));
Vue.component('registration', () => import('@/cards/registrations/Registration'));
Vue.component('new-registration', () => import('@/cards/registrations/NewRegistration'));
Vue.component('edit-registration', () => import('@/cards/registrations/EditRegistration'));
Vue.component('itemized', () => import('@/cards/registrations/Itemized'));
Vue.component('itinerary', () => import('@/cards/registrations/Itinerary'));
Vue.component('events', () => import('@/cards/registrations/Events'));
Vue.component('reg-days', () => import('@/cards/registrations/RegistrationDays'));
Vue.component('new-custom-event', () => import('@/cards/registrations/NewEvent'));
Vue.component('edit-custom-event', () => import('@/cards/registrations/EditEvent'));
Vue.component('flights', () => import('@/cards/registrations/Flights'));
Vue.component('new-flight', () => import('@/cards/registrations/NewFlight'));
Vue.component('edit-flight', () => import('@/cards/registrations/EditFlight'));
Vue.component('reg-hotels', () => import('@/cards/registrations/Hotels'));
Vue.component('new-reg-hotel', () => import('@/cards/registrations/NewHotel'));
Vue.component('edit-reg-hotel', () => import('@/cards/registrations/EditHotel'));
Vue.component('new-override-event', () => import('@/cards/registrations/NewOverrideEvent'));
Vue.component('edit-override-event', () => import('@/cards/registrations/EditOverrideEvent'));
Vue.component('addons', () => import('@/cards/registrations/Addons'));
Vue.component('new-addon', () => import('@/cards/registrations/NewAddon'));
Vue.component('edit-addon', () => import('@/cards/registrations/EditAddon'));
Vue.component('responses', () => import('@/cards/registrations/Responses'));
Vue.component('new-response', () => import('@/cards/registrations/NewResponse'));
Vue.component('edit-response', () => import('@/cards/registrations/EditResponse'));
Vue.component('payment-plan', () => import('@/cards/plans/Plan'));
Vue.component('edit-payment-plan', () => import('@/cards/plans/EditPlan'));
Vue.component('make-payment', () => import('@/cards/plans/MakePayment'));
Vue.component('new-payment-method', () => import('@/cards/plans/NewMethod'));
Vue.component('edit-payment-method', () => import('@/cards/plans/EditMethod'));
Vue.component('new-plan-item', () => import('@/cards/plans/NewPlanItem'));
Vue.component('edit-plan-item', () => import('@/cards/plans/EditPlanItem'));
Vue.component('new-transaction', () => import('@/cards/plans/NewTransaction'));
Vue.component('edit-transaction', () => import('@/cards/plans/EditTransaction'));
Vue.component('reg-notes', () => import('@/cards/registrations/Notes'));
Vue.component('new-reg-note', () => import('@/cards/registrations/NewNote'));
Vue.component('edit-reg-note', () => import('@/cards/registrations/EditNote'));

Vue.component('customers', () => import('@/cards/customers/Customers'));
Vue.component('search-customers', () => import('@/cards/customers/Search'));
Vue.component('customer', () => import('@/cards/customers/Customer'));
Vue.component('new-customer', () => import('@/cards/customers/NewCustomer'));
Vue.component('edit-customer', () => import('@/cards/customers/EditCustomer'));
Vue.component('customer-notes', () => import('@/cards/customers/Notes'));
Vue.component('new-customer-note', () => import('@/cards/customers/NewNote'));
Vue.component('edit-customer-note', () => import('@/cards/customers/EditNote'));
Vue.component('customer-documents', () => import('@/cards/customers/Documents'));
Vue.component('new-document', () => import('@/cards/customers/NewDocument'));
Vue.component('edit-document', () => import('@/cards/customers/EditDocument'));
Vue.component('customer-registrations', () => import('@/cards/customers/Registrations'));
Vue.component('customer-share', () => import('@/cards/customers/Share'));

Vue.component('billing', () => import('@/cards/billing/Billing'));
Vue.component('billing-due', () => import('@/cards/billing/Due'));

Vue.component('communication', () => import('@/cards/communication/Communication'));
Vue.component('marketing', () => import('@/cards/marketing/Marketing'));
Vue.component('reports', () => import('@/cards/reports/Reports'));
Vue.component('settings', () => import('@/cards/settings/Settings'));
Vue.component('user-settings', () => import('@/cards/user_settings/UserSettings'));
